import PropTypes from 'prop-types';

import modelPropTypes, { tagAttributes, carBrandAttributes } from 'core/utils/prop-types/model';

import H1 from 'core/components/H1';
import Tag from 'core/components/Tag';
import Tags from 'core/components/Tags';
import QutoBrandIcon from 'core/components/QutoBrandIcon';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Rug from 'site/components/Rug';
import { VerticalIndent } from 'site/components/Indents';

import BrandsMeta from 'site/pages/brand/BrandsMeta';

import styles from './index.styl';


function BrandsTags(props) {
  const {
    isMobile,
    tag,
    tagRoot,
    subtags,
    brand,
  } = props;

  const areEqualTags = tag.id === tagRoot.id;
  const title = tagRoot.attributes.title + (areEqualTags
    ? ''
    : ' ' + tag.attributes.title);

  /**
   * Приходится предварительно фильтровать массив тегов,
   * т.к. он неконсистентен и некоторые модели заполнены как `slug === root_slug`,
   * но некоторые не имеют рут-тега и для этого переопределяем его в начале массива как "Все модели".
   */
  const formattedTags = [].concat({
    id: 'rootTag',
    type: 'tag',
    attributes: {
      slug: tagRoot.attributes.slug,
      title: 'Все модели',
      tag_type: 'common_tag',
      root_slug: tagRoot?.attributes?.slug,
    },
  }, subtags
    .filter(item => item.attributes.slug !== item.attributes.root_slug)
    .sort((a, b) => {
      if (a.attributes.title > b.attributes.title) return 1;
      if (a.attributes.title < b.attributes.title) return -1;
      return 0;
    })
  );

  return (
    <Rug radius={0}>
      <div className={styles.header}>
        <div className={styles.title}>
          <QutoBrandIcon
            className={styles.icon}
            size={isMobile ? 48 : 80}
            brand={brand}
          />
          <H1>{title}</H1>
        </div>
        <VerticalIndent indent={isMobile ? 15 : 24} />
        <Tags>
          {formattedTags.map(item => {
            const isRoot = item.attributes.slug === item.attributes.root_slug;
            const isCurrent = item.attributes.slug === tag.attributes.slug;
            const link = isRoot
              ? `/theme/${tagRoot.attributes.slug}`
              : `/theme/${tagRoot.attributes.slug}/${item.attributes.slug}`;
            return (
              <Tag
                key={item.id}
                minWidth={50}
                type={isCurrent ? 'primary' : 'secondary'}
                link={link}
              >
                {item.attributes.title}
              </Tag>
            );
          })}
        </Tags>
        <BrandsMeta title={title} type={areEqualTags ? 'brand' : 'model'} />
      </div>
    </Rug>
  );
}

BrandsTags.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Бренд, соответствующий модели carBradnAttribute */
  brand: modelPropTypes(carBrandAttributes),
  /** Корневой тег, соответствующий модели tagAttributes */
  tagRoot: modelPropTypes(tagAttributes).isRequired,
  /** Тег модели */
  tag: modelPropTypes(tagAttributes).isRequired,
  /** Массив тегов для выбранной модели */
  subtags: PropTypes.arrayOf(modelPropTypes(tagAttributes)),
};

BrandsTags.defaultProps = {
  subtags: [],
};

const BrandsTagsWithHOCs = withBreakpoint(BrandsTags);
BrandsTagsWithHOCs.displayName = 'BrandTags';

export default BrandsTagsWithHOCs;

export { BrandsTags as StorybookComponent };
