import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { bebop as bebopApi } from 'core/api/definitions/bebop';
import { rawTopicsQuery } from 'core/queries/listPage';

import { replaceCurrentYear } from 'core/utils/string-helper';

import ListPage from 'core/components/ListPage';
import Page from 'core/components/Page';
import { PageIndent, Indent } from 'core/components/Wrappers';
import H1 from 'core/components/H1';
import PageLoader from 'core/components/Loader/PageLoader';

import { renderError } from 'core/utils/app-status-helper';

import RubricTopics from './RubricTopics';
import RubricLayout from 'site/components/RubricLayout';

import { GAME_COMPARE_LIMIT } from 'core/components/GameCompare/constants';

import styles from './index.styl';


function GameCompareRubric(props) {
  const { location } = props;

  const { data: rubric, isLoading: rubricIsLoading } = useRequest({
    queryKey: ['bebopApi', 'getRubric', {
      rubric_slug: 'ratings',
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params).catch(renderError),
  });

  const { data: rawTopics, isLoading: topicsAreLoading } = useRequest(rawTopicsQuery({
    location,
    limit: GAME_COMPARE_LIMIT,
    include: 'content',
    fields: 'link,headline,listHeadline,published_at',
    topic_type: 'game_compare,pair_compare',
  }));

  if (rubricIsLoading) return <PageLoader />;

  const {
    description,
    meta_title: metaTitle,
    meta_description: metaDescription,
  } = rubric.data.attributes;

  const titleMeta = replaceCurrentYear(metaTitle);
  const descriptionMeta = replaceCurrentYear(metaDescription);

  return (
    <Page
      title={titleMeta || 'Автомобильные новости России и мира, тест-драйвы автомобилей, автоспорт'}
      description={descriptionMeta}
    >
      <PageIndent>
        <RubricLayout>
          <H1>Рейтинги на Motor.ru</H1>
          <Indent bottom={20} />
          {description &&
            <Indent bottom={30}>
              <div className={styles.description}>{description}</div>
            </Indent>
          }
          <ListPage
            rawData={rawTopics}
            limit={GAME_COMPARE_LIMIT}
            isLoading={topicsAreLoading}
          >
            {({ content }) => topicsAreLoading
              ? <PageLoader />
              : <RubricTopics topics={content} />
            }
          </ListPage>
        </RubricLayout>
      </PageIndent>
    </Page>
  );
}

GameCompareRubric.propTypes = {
  location: PropTypes.object,
};

export default GameCompareRubric;
