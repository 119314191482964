import PropTypes from 'prop-types';

import RecommendationWidget from 'core/components/RecommendationWidget';
import H2 from 'core/components/H2';

import { withBreakpoint } from 'core/components/breakpoint';


function SiteRecommender({ isMobile }) {
  return (
    <RecommendationWidget
      blockId={isMobile ? '348a5fec0031477c89de7eb2e7049535' : '58559d4832df4838b332768de0c952a9'}
      title='Читайте также'
      headerComponent={H2}
    />
  );
}

SiteRecommender.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SiteRecommender);
