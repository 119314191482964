import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { cardReplacements } from 'site/utils';

import { Desktop, Mobile } from 'core/components/breakpoint';
import Feed from 'core/components/Feed';
import AdWrapper from 'core/components/Ad/AdWrapper';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import {
  VerticalIndent,
  NegativeMobileSideIndent,
} from 'site/components/Indents';

import { Card2Type1Ml } from 'site/cards/Card2';
import { Card3Type4M, Card3Type5Xs } from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';

import {
  SuperFooter,
  Context,
  Native1AsCard4,
  Native2AsCard4,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
  ListingSpecPulseSearch,
} from 'site/components/Ads/mobile';


function PulseList({ topics, isMobile }) {
  const first = topics.slice(0, 2);
  const second = topics.slice(2, 6);
  const third = topics.slice(6, 10);
  const fourth = topics.slice(10, 12);
  const fifth = topics.slice(12);

  const spacing = isMobile ? 10 : 16;

  const card = isMobile ? Card2Type1Ml : Card4;
  const cardNews = isMobile ? Card3Type5Xs : Card3Type4M;

  const commonFeedProps = {
    card,
    interitemSpacing: spacing,
  };

  return (
    <>
      <Feed
        {...commonFeedProps}
        content={first}
        cardsReplacement={cardReplacements(first, cardNews)}
      />
      <Desktop>
        <AdWrapper top={spacing}>
          <Native1AsCard4 />
        </AdWrapper>
      </Desktop>
      {second.length > 0 && (
        <>
          <VerticalIndent indent={spacing} />
          <Feed
            {...commonFeedProps}
            content={second}
            cardsReplacement={cardReplacements(second, cardNews)}
          />
          <AdWrapper top={spacing}>
            <Desktop>
              <SuperFooter />
            </Desktop>
            <Mobile>
              <NegativeMobileSideIndent>
                <Listing1 />
              </NegativeMobileSideIndent>
            </Mobile>
          </AdWrapper>
        </>
      )}
      {third.length > 0 && (
        <>
          <VerticalIndent indent={spacing} />
          <Feed
            {...commonFeedProps}
            content={third}
            cardsReplacement={cardReplacements(third, cardNews)}
          />
          <AdWrapper top={spacing}>
            <Desktop>
              <Context />
            </Desktop>
            <Mobile>
              <VerticalIndent indent={spacing} />
              <NegativeMobileSideIndent>
                <ListingSpecPulseSearch />
              </NegativeMobileSideIndent>
            </Mobile>
          </AdWrapper>
        </>
      )}
      {fourth.length > 0 && (
        <>
          <VerticalIndent indent={spacing} />
          <Feed
            {...commonFeedProps}
            content={fourth}
            cardsReplacement={cardReplacements(fourth, cardNews)}
          />
          <AdWrapper top={spacing}>
            <Desktop>
              <Native2AsCard4 />
            </Desktop>
            <Mobile>
              <NegativeMobileSideIndent>
                <Listing2 />
              </NegativeMobileSideIndent>
            </Mobile>
          </AdWrapper>
        </>
      )}
      {fifth.length > 0 && (
        <>
          <VerticalIndent indent={spacing} />
          <Feed
            {...commonFeedProps}
            content={fifth}
            cardsReplacement={cardReplacements(fifth, cardNews)}
          />
        </>
      )}
    </>
  );
}

PulseList.propTypes = {
  isMobile: PropTypes.bool,
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)).isRequired,
};

export default withBreakpoint(PulseList);
