import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import PageLoader from 'core/components/Loader/PageLoader';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import {
  VerticalIndent,
} from 'site/components/Indents';

import CardsList from 'site/components/CardsList';

import Card1 from 'site/cards/Card1';


function SectionContent({ topics, isDesktop, isLoading }) {
  if (isLoading) return <PageLoader />;
  if (!topics?.length) return null;

  return (
    <>
      <VerticalIndent indent={isDesktop ? 23 : 10} />
      <Card1 type={5} content={topics[0]} />
      <VerticalIndent indent={isDesktop ? 16 : 10} />
      <CardsList topics={topics.slice(1)} />
      <VerticalIndent indent={isDesktop ? 23 : 10} />
    </>
  );
}

SectionContent.propTypes = {
  isDesktop: PropTypes.bool,
  isLoading: PropTypes.bool,
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
};

export default withBreakpoint(SectionContent);
