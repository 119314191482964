import PropTypes from 'prop-types';
import queryString from 'core/libs/query-string';
import cx from 'classnames';

import { bebop as bebopApi } from 'core/api/definitions/bebop';
import useRequest from 'core/hooks/useRequest';
import {
  tagQuery,
  topicsQuery,
} from 'core/queries/tagTopics';

import TagTopics from 'core/components/TagTopics';
import Bouesque from 'core/components/Bouesque';
import Mjolnir from 'core/components/Mjolnir';
import H5 from 'core/components/H5';
import { Mobile, Desktop } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import PageLoader from 'core/components/Loader/PageLoader';

import bindProps from 'core/components/bindProps';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import { renderError } from 'core/utils/app-status-helper';

import brandsQuery from 'site/queries/brands';

import SelfPromo from 'core/components/SelfPromo';
import { PartnersVertical } from 'site/components/Partners';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card4 from 'site/cards/Card4';

import {
  VerticalFirst,
  VerticalSecond,
} from 'site/components/Ads/desktop';

import {
  NegativeMobileSideIndent,
  VerticalIndent,
} from 'site/components/Indents';

import ViewportHeightWrapper from 'site/components/ViewportHeightWrapper';
import BrandsTags from 'site/components/BrandsTags';
import TopicsFilters from 'site/components/TopicsFilters';
import CardsList from 'site/components/CardsList';
import FooterAds from 'site/components/FooterAds';

import { filters as filtersData } from 'site/constants';

import styles from './index.styl';

const Card1Type5 = bindProps({ type: 5 })(Card1);
const topicsLimit = 15;


function BrandPage(props) {
  const {
    isDesktop,
    location,
    history,
    match,
    match: {
      params: {
        level_2: brandSlug,
      },
    },
  } = props;

  const filters = queryString.parse(location.search);
  const isValidSlug = filtersData.find(({ slug }) => slug === filters.slug);
  const {
    data: tag,
    isLoading: tagIsLoading,
    isFetching: tagIsFetching,
  } = useRequest({
    ...tagQuery({ history, match }),
    keepPreviousData: true,
  });
  const { data: brands = [] } = useRequest(brandsQuery({
    limit: 1,
    'filter[brand]': brandSlug,
  }));

  const {
    data: tagRoot,
    isLoading: tagRootIsLoading,
    isFetching: tagRootIsFetching,
  } = useRequest({
    queryKey: ['bebopApi', 'getTag', {
      tag_slug: brandSlug,
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params)
      .catch(err => renderError(err, history)),
    select: denormalizeData,
    keepPreviousData: true,
  });

  const {
    data: subtags,
    isFetching: subtagsAreFetching,
  } = useRequest({
    queryKey: ['bebopApi', 'getTags', {
      tag_root: brandSlug,
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params).catch(renderError),
    select: denormalizeData,
  });

  const { data: rawTopics, isLoading: contentIsLoading } = useRequest(topicsQuery({
    location,
    match,
    history,
    limit: topicsLimit,
    include: filterRequiredParams([Card1, Card2, Card4], 'include'),
    fields: filterRequiredParams([Card1, Card2, Card4], 'fields'),
    ...Object.keys(filters).length && isValidSlug && { [filters.type]: filters.slug },
  }));

  const tagsAreLoading = tagIsLoading || tagRootIsLoading;
  const tagsAreFetching = tagIsFetching || tagRootIsFetching;

  return (
    <PageIndent>
      <ColumnLayout
        rightColumn={(
          <>
            <Bouesque />
            <VerticalIndent indent={16} />
            <VerticalFirst />
            <VerticalIndent indent={16} />
            <SelfPromo />
            <VerticalIndent indent={16} />
            <PartnersVertical />
            <VerticalIndent indent={16} />
            <VerticalSecond />
            <VerticalIndent indent={16} />
          </>
        )}
      >
        <ViewportHeightWrapper>
          {tagsAreLoading && <PageLoader />}
          {!tagsAreLoading &&
            <TagTopics
              titleComponent={() => (
                <div
                  className={cx(
                    styles.filters,
                    (tagsAreFetching || subtagsAreFetching) && 'lb-skeleton')}
                >
                  <NegativeMobileSideIndent>
                    <BrandsTags
                      brand={brands[0]}
                      tagRoot={tagRoot}
                      subtags={subtags}
                      tag={tag}
                    />
                    <VerticalIndent indent={isDesktop ? 16 : 10} />
                    <TopicsFilters />
                    <VerticalIndent indent={isDesktop ? 16 : 10} />
                  </NegativeMobileSideIndent>
                </div>
              )}
              tag={tag}
              topics={rawTopics}
              limit={topicsLimit}
              isLoading={tagIsLoading}
            >
              {({ topics }) => {
                if (contentIsLoading) return <PageLoader />;
                if (!topics?.length) return <H5>Материалов по теме не найдено</H5>;

                return (
                  <>
                    <Desktop>
                      {topics.length > 4 ? (
                        <>
                          <Mjolnir
                            heroCard={Card1Type5}
                            card={Card1}
                            interitemSpacing={0}
                            content={topics.slice(0, 4)}
                          />
                          <VerticalIndent indent={isDesktop ? 16 : 10} />
                          <CardsList topics={topics.slice(4)} />
                        </>
                      ) : (
                        <>
                          <Card1 type={5} content={topics[0]} />
                          <VerticalIndent indent={isDesktop ? 16 : 10} />
                          <CardsList topics={topics.slice(1)} />
                        </>
                      )}
                    </Desktop>
                    <Mobile>
                      <VerticalIndent indent={10} />
                      <NegativeMobileSideIndent>
                        <Card1 type={5} content={topics[0]} />
                      </NegativeMobileSideIndent>
                      <VerticalIndent indent={isDesktop ? 16 : 10} />
                      <CardsList topics={topics.slice(1)} />
                    </Mobile>
                  </>
                );
              }}
            </TagTopics>
          }
          <VerticalIndent indent={isDesktop ? 23 : 10} />
        </ViewportHeightWrapper>
        <VerticalIndent indent={isDesktop ? 16 : 10} />
        <AdWrapper bottom={isDesktop ? 16 : 10}>
          <FooterAds />
        </AdWrapper>
      </ColumnLayout>
    </PageIndent>
  );
}

BrandPage.propTypes = {
  isDesktop: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      level_2: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.object,
};

export default withBreakpoint(BrandPage);
