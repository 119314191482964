import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';

import Grid from 'core/components/Grid';
import InfiniteTopic from 'core/components/InfiniteTopic';
import Topic from 'core/components/Topic';
import { Mobile } from 'core/components/breakpoint';
import EmptyWrapper from 'core/components/EmptyWrapper';


import { resolve } from 'core/utils/env';
import { isGameCompare } from 'core/components/GameCompare/utils';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Rug from 'site/components/Rug';
import ViewportHeightWrapper from 'site/components/ViewportHeightWrapper';
import { TopBanner } from 'site/components/Ads/mobile';

import NewsTopic from './content/news';
import ArticleTopic from './content/article';
import MediaTopic from './content/media';
import GameCompareTopic from './content/gameCompare';

import TopicSkeleton from './Skeleton';
import titleBuilder from './titleBuilder';


const topicTypeMapping = {
  news: NewsTopic,
  article: ArticleTopic,
  gallery: MediaTopic,
  video: MediaTopic,
  game_compare: GameCompareTopic,
  pair_compare: GameCompareTopic,
};

function noopComponent(type) {
  return () => (
    <div>
      Не поддерживаемый тип топика: {type}
    </div>
  );
}

function topicRenderer(topic, enhancedTopic) {
  const {
    content,
    infinityIndex,
  } = enhancedTopic;

  const {
    topic_type: topicType,
    is_premium: isPremiumTopic,
  } = content.attributes;

  const Unsupported = noopComponent(topicType);
  const Content = topicTypeMapping[topicType] || Unsupported;
  const Wrapper = isPremiumTopic ? EmptyWrapper : ViewportHeightWrapper;
  const ContentWrapper = isPremiumTopic ? EmptyWrapper : Rug;

  return (
    <Wrapper>
      {infinityIndex > 0 && (
        <Mobile>
          <TopBanner lazy />
        </Mobile>
      )}
      <ContentWrapper radius={0} withoutBorder>
        <Content infinityIndex={infinityIndex} content={content} />
      </ContentWrapper>
    </Wrapper>
  );
}

function TopicPage(props) {
  const {
    isDesktop,
    location: {
      pathname,
    },
  } = props;

  const { data, isLoading } = useRequest(topicQuery(props));

  if (isLoading) return <TopicSkeleton />;

  const isGameComparePage = isGameCompare(pathname);

  if (isGameComparePage) {
    return (
      <Topic content={data}>
        {topicRenderer}
      </Topic>
    );
  }

  return (
    <Grid>
      <InfiniteTopic
        content={data}
        rcmBlockId={resolve({
          '*': '1adbf4bf0bd34236bdf7405403e99ba1',
          'production': isDesktop ? '92cbf19b8a194740825663f31d352102' : 'c5823faa4db0421fb17b5bd3842fc7b4',
        })}
        titleBuilder={titleBuilder}
      >
        {topicRenderer}
      </InfiniteTopic>
    </Grid>
  );
}

TopicPage.propTypes = {
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
};

export default withBreakpoint(TopicPage);
