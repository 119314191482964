import PropTypes from 'prop-types';

import {
  rawRubricQuery,
  rawTopicsQuery,
} from 'core/queries/rubric';

import useRequest from 'core/hooks/useRequest';

import Rubric from 'core/components/Rubric';

import { filterRequiredParams } from 'core/utils/api';

import RubricLayout from 'site/components/RubricLayout';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';

import SectionContent from './Content';

const limit = 10;


function RubricPage(props) {
  const { location, match, history } = props;

  const [
    { data: rawRubric, isLoading: rubricIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({ queries: [
    rawRubricQuery({ match, history }),
    rawTopicsQuery({
      location,
      match,
      history,
      limit,
      include: filterRequiredParams([Card1, Card2, Card3, Card4], 'include'),
      fields: filterRequiredParams([Card1, Card2, Card3, Card4], 'fields'),
    }),
  ] });

  return (
    <RubricLayout>
      <Rubric
        rawRubric={rawRubric}
        rawTopics={rawTopics}
        limit={limit}
        headerSpacing={0}
        isLoading={rubricIsLoading || topicsAreLoading}
      >
        { ({ topics }) => <SectionContent topics={topics} isLoading={topicsAreLoading} /> }
      </Rubric>
    </RubricLayout>
  );
}

RubricPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default RubricPage;
