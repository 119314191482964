import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import useRequest from 'core/hooks/useRequest';
import {
  tagQuery,
  topicsQuery,
} from 'core/queries/tagTopics';

import TagTopics from 'core/components/TagTopics';

import { filterRequiredParams } from 'core/utils/api';

import RubricLayout from 'site/components/RubricLayout';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';

import SectionContent from './Content';

const limit = 10;


function TagPage({ location, history, match }) {
  const [
    { data: tag, isLoading: tagIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({ queries: [
    tagQuery({ history, match }),
    topicsQuery({
      location, history, match,
      limit,
      include: filterRequiredParams([Card1, Card2, Card3, Card4], 'include'),
      fields: filterRequiredParams([Card1, Card2, Card3, Card4], 'fields'),
    }),
  ] });

  return (
    <RubricLayout>
      <TagTopics
        tag={tag}
        topics={rawTopics}
        limit={limit}
        headerSpacing={0}
        isLoading={tagIsLoading || topicsAreLoading}
      >
        {({ topics, title, titleMeta, descriptionMeta }) => {
          return (
            <>
              <Helmet>
                <title>{titleMeta || `${title}: Новости, тест-драйвы, обзоры`}</title>
                <meta name='description' content={descriptionMeta || `Новости о ${title}: тест-драйвы, обзоры, репортажи, фото и видео на Motor.ru`} />
              </Helmet>
              <SectionContent topics={topics} isLoading={topicsAreLoading} />
            </>
          );
        }}
      </TagTopics>
    </RubricLayout>
  );
}

TagPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
};

export default TagPage;
