import { compose } from 'core/libs/recompose';

import bindPropsHOC from 'core/components/bindProps';

import Ad from 'core/components/Ad';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';
import cardReadMore from './styles/CardReadMore';
import listingSpecNativeStyles from './styles/LisitngSpecNative';
/**
 * У мобильных баннеров нужно указывать мобильный `siteId` (`begun-auto-pad`).
 */
const commonProps = {
  siteId: '459770140',
  height: 250,
};
const listingSpecProps = bindPropsHOC({
  ...commonProps,
  name: 'Listing Spec (COM)',
  lazy: true,
  blockId: '462240324',
  options: {
    p1: 'bxymd',
    p2: 'fqbd',
    pct: 'a',
  },
});

export const TopBanner = bindPropsHOC({
  ...commonProps,
  name: 'TopBanner',
  blockId: '460313267',
  options: {
    p1: 'bvqmo',
    p2: 'fexc',
    pct: 'a',
  },
})(Ad);

export const Fullscreen = bindPropsHOC({
  ...commonProps,
  name: 'Fullscreen',
  height: null,
  blockId: '460313271',
  options: {
    p1: 'bvqms',
    p2: 'etsa',
    pct: 'a',
  },
})(Ad);

export const Rich = bindPropsHOC({
  ...commonProps,
  name: 'Rich',
  height: null,
  blockId: '460313273',
  options: {
    p1: 'bvqmt',
    p2: 'ffhi',
    pct: 'a',
  },
})(Ad);

export const Listing1 = bindPropsHOC({
  ...commonProps,
  name: 'Listing1 (COM)',
  lazy: true,
  blockId: '460313247',
  options: {
    p1: 'bwttn',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Listing2 = bindPropsHOC({
  ...commonProps,
  name: 'Listing2 (COM)',
  lazy: true,
  blockId: '460313249',
  options: {
    p1: 'bwttg',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Listing3 = bindPropsHOC({
  ...commonProps,
  name: 'Listing3 (COM)',
  lazy: true,
  blockId: '460313251',
  options: {
    p1: 'bwttn',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Listing4 = bindPropsHOC({
  ...commonProps,
  name: 'Listing4 (COM)',
  lazy: true,
  blockId: '460313253',
  options: {
    p1: 'bwttm',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const ListingSpec = compose(
  listingSpecProps,
  listingSpecNativeStyles
)(Ad);

export const ListingSpecPulseSearch = compose(
  listingSpecProps,
  cardReadMore,
)(Ad);

export const Footer = bindPropsHOC({
  ...commonProps,
  name: 'Footer (COM)',
  lazy: true,
  blockId: '460313263',
  options: {
    p1: 'bxbbx',
    p2: 'fexd',
    pct: 'a',
  },
})(Ad);

export const Sponsored = compose(
  bindPropsHOC({
    ...commonProps,
    name: 'Sponsored (COM)',
    height: null,
    blockId: '460313265',
    options: {
      p1: 'bwujb',
      p2: 'fooq',
      pct: 'c',
    },
  }),
  sponsoredStyles,
)(Ad);

export const InPage = bindPropsHOC({
  ...commonProps,
  name: 'InPage',
  height: 200,
  blockId: '460313269',
  options: {
    p1: 'bvqmr',
    p2: 'fexd',
    pct: 'a',
  },
})(Ad);

export const Content1 = bindPropsHOC({
  ...commonProps,
  name: 'Content1',
  lazy: true,
  blockId: '460313255',
  options: {
    p1: 'bwtth',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Content2 = bindPropsHOC({
  ...commonProps,
  name: 'Content2',
  lazy: true,
  blockId: '460313257',
  options: {
    p1: 'bwttj',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Content3 = bindPropsHOC({
  ...commonProps,
  name: 'Content3',
  lazy: true,
  blockId: '460313259',
  options: {
    p1: 'bwttk',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Content4 = bindPropsHOC({
  ...commonProps,
  name: 'Content4',
  lazy: true,
  blockId: '460313261',
  options: {
    p1: 'bwttl',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const ContentSpec = compose(
  bindPropsHOC({
    ...commonProps,
    name: 'ContentSpec',
    lazy: true,
    blockId: '462240332',
    options: {
      p1: 'bxyme',
      p2: 'fqbd',
      pct: 'a',
    },
  }),
  cardReadMore,
)(Ad);

export const InGallery = bindPropsHOC({
  ...commonProps,
  name: 'InGallery',
  blockId: '579389974',
  disableAutoInit: true,
  height: null,
})(Ad);

export const PopUp = bindPropsHOC({
  ...commonProps,
  name: 'PopUp',
  height: null,
  blockId: '579502877',
  options: {
    siteId: '459770140',
  },
})(Ad);
