import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import withTopic from 'core/components/Topic/withTopic';

import Recommender from 'site/components/Recommender';
import { PartnersHorizontal } from 'site/components/Partners';

import {
  VerticalIndent,
} from 'site/components/Indents';

/**
 * Компонент создан из-за необходимости разделения Footer на две части, с разными backgrounds
 */
function TopicFooterBottom(props) {
  const {
    isMobile,
    topic,
  } = props;

  const { infinityIndex } = topic || {};

  return (
    <Fragment>
      {!topic || (infinityIndex % 2 !== 0) && (
        <>
          <Recommender />
          <VerticalIndent indent={isMobile ? 15 : 30} />
        </>
      )}
      {!topic || (infinityIndex % 2 === 0) && (
        <>
          <PartnersHorizontal />
          <VerticalIndent indent={isMobile ? 10 : 30} />
        </>
      )}
    </Fragment>
  );
}

TopicFooterBottom.propTypes = {
  isMobile: PropTypes.bool,
  topic: PropTypes.shape({
    infinityIndex: PropTypes.number,
  }),
  isPremiumTopic: PropTypes.bool,
};

export default withTopic(withBreakpoint(TopicFooterBottom));
