import { bebop as bebopApi } from 'core/api/definitions/bebop';

import { denormalizeData } from 'core/utils/api';
import { resolve as resolveEnv } from 'core/utils/env';

export default queryParams => {
  const qutoEndpoint = resolveEnv({
    '*': 'https://stage.quto.ru/unity/v1/catalog/brands/',
    'production': 'https://quto.ru/unity/v1/catalog/brands/',
  });

  return {
    queryKey: ['bebopApi', 'getExternal', qutoEndpoint, {
      'attributes[car_brand]': 'base,image',
      ...queryParams,
    }],
    queryFn: ({ queryKey: [, method, url, params] }) => bebopApi[method](url, params).catch(() => []),
    select: denormalizeData,
  };
};
